import React, {useContext} from 'react';
import TextFieldControl from 'components/inputs/textField/TextFieldControl';
import DropdownControl from 'components/inputs/DropdownControl';
import {Grid, Typography} from '@mui/material';
import {ModalContext} from 'context/ContactModal';

const PhysicalAuth = props => {
    const {form, ...other} = props;
    const {control} = form;
    const {handleClickModal} = useContext(ModalContext);

    return (
        <Grid container item xs={12} alignItems={'end'} spacing={3} sx={{justifyContent: 'center', flexWrap: 'nowrap'}}>
            <Grid item>
                <Typography variant={'body2'} sx={{color: '#242E45', pb: 0.5}}>
                    Телефон
                </Typography>
                <DropdownControl
                    disabled
                    defaultValue={'+7'}
                    items={[{id: '+7'}]}
                    control={control}
                    name={'phoneCode'}
                    placeholder={'+7'}
                />
            </Grid>
            <Grid item>
                <TextFieldControl
                    onClick={() => handleClickModal()}
                    mask={'(000) 000-00-00'}
                    type="number"
                    control={control}
                    name={'telephoneNumber'}
                    placeholder={'(999) 888-77-66'}
                />
            </Grid>
        </Grid>
    );
};

export default PhysicalAuth;
