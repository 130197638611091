import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import {styled} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useMutation} from '@apollo/client';
import {LOGIN} from 'graphql/order/mutation/login';
import {LoadingButton} from '@mui/lab';
import LegalAuth from 'components/Header/components/LegalAuth';
import PhysicalAuth from 'components/Header/components/PhysicalAuth';
import PincodeStyled from 'components/Header/components/PinCode';
import {yupResolver} from '@hookform/resolvers/yup';
import {useRouter} from 'next/router';
import moment from 'moment';
import cookies from 'js-cookie';
import LogoutIcon from '@mui/icons-material/Logout';

import * as yup from 'yup';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ROUTES from 'constants/routes';

const AuthorizationDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: '8px',
        width: 382,
        paddingBottom: '44px'
    },
    '& .MuiDialogContent-root': {
        overflowY: 'auto',
        padding: 0
    },
    '& .MuiDialogActions-root': {
        padding: 0,
        justifyContent: 'center',
        marginBottom: 6,
        marginTop: 30
    }
}));

const DialogHeader = props => {
    const {onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2, pb: 0, textAlign: 'end'}} {...other}>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        color: theme => theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

DialogHeader.propTypes = {
    onClose: PropTypes.func.isRequired
};

const Authorization = props => {
    const {setOpenModal, openModal} = props;
    const {push, query} = useRouter();
    // Переключатель табов
    const [tab, setTab] = useState('physical');
    // Пинкод
    const [pin, setPin] = useState(false);

    const [login, {data, loading}] = useMutation(LOGIN);
    const handleChange = (event, newValue) => {
        setTab(newValue);
        reset({});
    };

    const handleClickOpen = () => {
        cookies.get('accessToken') ? cookies.remove('accessToken', push('/')) : setOpenModal(true);
        props.aboutMe();
    };

    const pinClose = () => {
        setPin(false);
    };
    const handleClose = () => {
        pinClose();
        setOpenModal(false);
        reset({});
    };

    const schema =
        tab === 'physical'
            ? yup.object().shape({
                  telephoneNumber: yup
                      .string()
                      .required('Обязательное поле')
                      .matches(/^[(][0-9]{3}[)][ ][0-9]{3}[-][0-9]{2}[-][0-9]{2}$/, 'Неверный формат номера')
              })
            : yup.object().shape({
                  contractNumber: yup
                      .string()
                      .required('Обязательное поле')
                      .matches(/^[0-9]+$/, 'Допустимы только числа'),
                  password: yup.string().required('Обязательное поле').min(6, 'Минимум 6 символов')
              });

    const form = useForm({
        resolver: yupResolver(schema)
    });
    const {reset, getValues, setError, handleSubmit} = form;

    //Юр. лица
    const onLegalAuth = info => {
        login({
            variables: {
                password: info?.password,
                contractNumber:
                    info?.contractNumber.length < 9
                        ? info?.contractNumber.toString().padStart(9, '0')
                        : info?.contractNumber
            }
        })
            .then(res => {
                const accessToken = res.data?.login?.accessToken;
                cookies.set('accessToken', accessToken, {expires: moment().add(30, 'days').toDate()});
                // handleClose не обязателен, т.к. страница перезагрузится, но так приятнее
                handleClose();
                props.aboutMe();
                push(ROUTES.AWAITING_DISPATCH_LIST);
            })
            .catch(err => {
                setError('contractNumber', {
                    type: 'auth',
                    message: ''
                });
                setError('password', {type: 'auth', message: 'Неверный логин или пароль'});
            });
    };
    // Физ. лица
    const onPhysicalAuth = info => {
        // setPin(true);
    };

    const onSubmit = info => {
        if (tab === 'physical') {
            onPhysicalAuth(info);
        } else {
            onLegalAuth(info);
        }
    };
    useEffect(() => {
        if (query.url) {
            handleClickOpen();
        }
    }, []);
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            {props.info && !props.err ? (
                <>
                    <Box sx={{ml: 6}}>
                        <Typography variant={'h6'} sx={{color: 'grey.light'}}>
                            {props.info?.me.organizationName}
                        </Typography>
                    </Box>

                    <IconButton sx={{ml: 6}} aria-label="close" color={'primary'} onClick={handleClickOpen}>
                        <LogoutIcon />
                    </IconButton>
                </>
            ) : (
                <Button
                    sx={{ml: [0, 6]}}
                    variant={'contained'}
                    color={'primary'}
                    size="small"
                    onClick={handleClickOpen}>
                    <Typography variant={'h6'} color={'white'}>
                        Вход
                    </Typography>
                </Button>
            )}

            <AuthorizationDialog
                component={'form'}
                onSubmit={handleSubmit(onSubmit)}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openModal}>
                <DialogHeader id="customized-dialog-title" onClose={handleClose} />
                <Grid container sx={{mb: 3, mt: -1, textAlign: 'center'}}>
                    <Grid item xs={12}>
                        <Typography variant={'h4'}>Вход</Typography>
                    </Grid>
                </Grid>
                <DialogContent sx={{pt: 8.5, pb: 6, mx: 6}}>
                    <Box
                        sx={{
                            width: '100%',
                            typography: 'body1',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'stretch'
                        }}>
                        {pin ? (
                            <>
                                <Stack direction={'row'} sx={{mb: 5, alignItems: 'flex-end'}}>
                                    <ArrowBackIosIcon
                                        sx={{
                                            height: 24,
                                            p: '6px 2px 2px',
                                            color: theme => theme.palette.grey[500],
                                            cursor: 'pointer'
                                        }}
                                        onClick={pinClose}
                                    />
                                    <Typography variant={'body2'} sx={{color: theme => theme.palette.blue.dark, ml: 2}}>
                                        Код отправлен на {getValues('phoneCode') + ' ' + getValues('telephoneNumber')}
                                    </Typography>
                                </Stack>
                                <PincodeStyled />
                            </>
                        ) : (
                            <TabContext value={tab}>
                                <TabList
                                    onChange={handleChange}
                                    textColor="secondary"
                                    sx={{justifyContent: 'center', flexAlign: 'stretch'}}>
                                    <Tab label="Физическое лицо" value="physical" sx={{width: '50%', px: 0}} />
                                    <Tab label="Юридическое лицо" value="legal" sx={{width: '50%', px: 0}} />
                                </TabList>
                                <TabPanel value="physical" sx={{py: [1, 3]}}>
                                    <PhysicalAuth {...{form}} />
                                </TabPanel>
                                <TabPanel value="legal" sx={{py: [1, 3]}}>
                                    <LegalAuth {...{form}} />
                                </TabPanel>
                            </TabContext>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    {pin ? (
                        <Button
                            variant="text"
                            color={'primary'}
                            sx={{px: [16, 5.5], mt: 2.5, color: theme => theme.palette.primary.main}}>
                            Выслать код повторно
                        </Button>
                    ) : (
                        <LoadingButton
                            sx={{width: ['100%', 136], mx: 6, py: 1.5}}
                            type={'submit'}
                            loading={loading}
                            loadingPosition={'start'}
                            variant={'contained'}
                            color={'primary'}>
                            Войти
                        </LoadingButton>
                    )}
                </DialogActions>
            </AuthorizationDialog>
        </Box>
    );
};

export default Authorization;
