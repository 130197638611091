import ROUTES from 'constants/routes';

export const routes = [
    {
        title: 'Ожидают отправки',
        href: ROUTES.AWAITING_DISPATCH_LIST
    },
    {
        title: 'Ожидают получения',
        href: ROUTES.AWAITING_ISSUE_LIST
    }
];
