const ROUTES = {
    AWAITING_DISPATCH_LIST: '/order/dispatch-list',
    AWAITING_ISSUE_LIST: '/order/issue-list',
    ORDER_VIEW: '/order/view',
    ORDER_VIEW_ISSUE: '/order/view-issue',
    ORDER_CREATE: '/order/create/delivery-method',
    ORDER_EDIT: '/order/edit/delivery-method',
    QUESTIONS: '/questions',
    CONTACTS: '/contacts',
    ABOUT_US: '/about-us',
    INDEX: '/'
};

export default ROUTES;
