import React from 'react';
import {Box, Container, Grid, SvgIcon, Typography, useMediaQuery} from '@mui/material';
import logo from '/public/white-logo.svg';
import Link from 'components/Link';
import ROUTES from 'constants/routes';
import moment from 'moment';
import {useQuery} from '@apollo/client';
import {ME} from 'graphql/client/query/me';
import {routes as landingRoutes} from 'layouts/LandingLayout/routes';
import {routes as ppvRoutes} from 'layouts/PpvLayout/routes';

const Footer = () => {
    const {data, error, refetch} = useQuery(ME);
    const isMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const routes =
        (data?.me?.type === 'PHYSICAL' && !error && landingRoutes) ||
        (data?.me?.type === 'LEGAL' && !error && ppvRoutes) ||
        landingRoutes;
    const FOOTERREFS2 = [
        {title: 'Оферта', href: ''},
        {title: 'Политика сбора ПДн клиентов', href: ''},
        {title: 'Пользовательское соглашение', href: ''}
    ];
    return (
        <Box
            sx={{
                backgroundColor: theme => theme.palette.blue.dark
            }}>
            <Container>
                <Box
                    sx={{
                        mx: 'auto',
                        py: 3.75,
                        display: 'flex',
                        flexDirection: ['column', 'row'],
                        justifyContent: 'space-between',
                        alignContent: 'space-between',
                        flexWrap: 'wrap'
                    }}>
                    <Grid container sx={{pb: 3.25, flexDirection: ['column', 'row']}}>
                        <Grid sx={{order: ['-1', '0'], pb: [5, 0]}}>
                            <Link href={ROUTES.ABOUT_US}>
                                <SvgIcon
                                    inheritViewBox
                                    component={logo}
                                    sx={{
                                        height: 46,
                                        width: 114,
                                        pt: 0.5
                                    }}
                                />
                            </Link>
                        </Grid>
                        <Grid
                            item
                            sx={{
                                display: 'flex',
                                flexGrow: '1',
                                flexDirection: ['column', 'row'],
                                alignItems: ['left', 'center'],
                                justifyContent: {xs: 'space-evenly', md: 'center'}
                            }}>
                            {routes.map((item, index) => (
                                <Link
                                    sx={{
                                        display: 'block',
                                        textDecoration: 'none',
                                        mb: [2.5, 0],
                                        px: [0, 0, 8],
                                        fontWeight: 400,
                                        color: 'white'
                                    }}
                                    href={item.href}
                                    key={index}>
                                    {item.title}
                                </Link>
                            ))}
                        </Grid>

                        <Grid sx={{order: ['-1', '0'], textAlign: ['start', 'end']}}>
                            <Typography
                                variant={'h3'}
                                sx={{
                                    fontWeight: '400',
                                    color: 'white',
                                    mb: 1.25
                                }}>
                                +7 (920) 587-50-66
                            </Typography>
                            <Typography
                                variant={'h3'}
                                sx={{
                                    fontWeight: '400',
                                    color: 'white',
                                    mb: [6.25, 0]
                                }}>
                                admin@ldclogistics.ru
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        sx={{
                            flexDirection: ['column', 'row']
                        }}>
                        <Grid
                            item
                            xs={isMd ? 4 : 12}
                            sx={{
                                order: ['3', '0'],
                                color: theme => theme.palette.grey.light,
                                gap: '20px',
                                pb: [6, 0]
                            }}>
                            <Typography variant={'verySmall'}>
                                © 2017 – {moment().format('YYYY')}, {!isMd && <br />} Курьерская компания LDC
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={isMd ? 6 : 12}
                            sx={{
                                display: 'flex',
                                flexDirection: ['column', 'row'],
                                flexGrow: '1',
                                alignItems: ['left', 'center'],
                                pb: [6, 0]
                            }}>
                            {FOOTERREFS2.map((item, index) => (
                                <Link
                                    variant={'verySmall'}
                                    sx={{
                                        display: 'block',
                                        textDecoration: 'none',
                                        mb: [2.5, 0],
                                        mr: 3.75,
                                        fontWeight: 400,
                                        color: theme => theme.palette.grey.light,
                                        '&:last-of-type': {
                                            marginRight: 0
                                        }
                                    }}
                                    href={item.href}
                                    key={index}>
                                    {item.title}
                                </Link>
                            ))}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
