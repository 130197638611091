import ROUTES from 'constants/routes';

export const routes = [
    {
        title: 'О компании',
        href: ROUTES.INDEX
    },
    // {
    //     title: 'Частые вопросы',
    //     href: ROUTES.QUESTIONS
    // },
    {
        title: 'Контакты',
        href: ROUTES.CONTACTS
    }
];
