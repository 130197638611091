import {gql} from '@apollo/client';

export const ME = gql`
    query me {
        me {
            id
            lastName
            name
            secondName
            organizationName
            type
            phoneNumber
            contractNumber
            issuePoint {
                id
                kind
                deliveryAddress {
                    id
                    address {
                        city
                    }
                }
        } }
    }
`;
