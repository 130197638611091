import React, {useEffect, useState} from 'react';
import TextFieldControl from 'components/inputs/textField/TextFieldControl';
import DropdownControl from 'components/inputs/DropdownControl';
import {Grid, Typography} from '@mui/material';

const LegalAuth = props => {
    const {form, ...other} = props;
    const {control} = form;
    return (
        <Grid container item xs={12} alignItems={'center'} spacing={3}>
            <Grid item xs={12}>
                <Typography variant={'body2'} sx={{color: '#242E45', pb: 0.5}}>
                    Номер договора
                </Typography>
                <TextFieldControl
                    type="number"
                    mask={'00000000000'}
                    autoFocus={'true'}
                    control={control}
                    name={'contractNumber'}
                    placeholder={'------------'}
                />
            </Grid>

            <Grid item xs={12}>
                <Typography variant={'body2'} sx={{color: '#242E45', pb: 0.5}}>
                    Пароль
                </Typography>
                <TextFieldControl type={'password'} control={control} name={'password'} placeholder={'Пароль'} />
            </Grid>
        </Grid>
    );
};

export default LegalAuth;
