import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {styled} from '@mui/material/styles';
const PREFIX = 'code-';

const PinCode = props => {
    const {autoFocus, className, length: codeLength, error, validate, cleanError} = props;
    const [code, setCode] = useState(Array(4).fill(''));
    const numberRefs = useRef([]);
    // При нажатии всех цифр
    useEffect(() => {
        if (code.every(element => element !== '')) {
            numberRefs.current[codeLength - 1].focus();
            numberRefs.current[codeLength - 1].blur();
        }
    }, [code]);

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                numberRefs.current[0].focus();
                setCode(Array(4).fill(''));
                cleanError();
            }, 2000);
        }
    }, [error]);

    const onInput = ({target: {name, value}}) => {
        const i = parseInt(name.split(PREFIX)[1]);
        if (value.length <= 1 && !isNaN(value)) {
            let arr = code;
            arr[i] = value;
            setCode([...arr]);
            if (i < codeLength - 1 && arr[i] !== '') {
                numberRefs.current[i + 1].focus();
            }
        } else {
            let arr = code;
            arr[i] = '';
            setCode([...arr]);
        }
    };

    const handleKeyDown = (evt, index) => {
        let key = evt.nativeEvent.key;
        switch (key) {
            case 'Backspace':
                if (index > 0 && code[index] === '') {
                    let arr = code;
                    arr[index - 1] = '';
                    setCode([...arr]);
                    numberRefs.current[index - 1].focus();
                }
                break;
            case 'ArrowRight':
                if (index < codeLength - 1) {
                    numberRefs.current[index + 1].focus();
                }
                break;
            case 'ArrowLeft':
                if (index > 0) {
                    numberRefs.current[index - 1].focus();
                    let val = numberRefs.current[index - 1].value;
                    numberRefs.current[index - 1].value = '';
                    setTimeout(() => (numberRefs.current[index - 1].value = val));
                }
                break;
        }
    };

    return (
        <div className={className}>
            <div className="flex justify-center">
                {Array(codeLength)
                    .fill()
                    .map((item, index) => (
                        <input
                            type="tel"
                            pattern="[0-9]*"
                            key={index}
                            name={PREFIX + index}
                            ref={ref => (numberRefs.current[index] = ref)}
                            className={clsx('input', {
                                ['active']: code[index] !== '',
                                ['error']: error
                            })}
                            maxLength={1}
                            autoFocus={index === 0}
                            onKeyDown={evt => handleKeyDown(evt, index)}
                            onInput={onInput}
                            autoComplete="off"
                            value={code[index]}
                        />
                    ))}
            </div>
            {error && <div className={'textError'}>Неверный код</div>}
        </div>
    );
};

PinCode.defaultProps = {
    length: 4
};

PinCode.propTypes = {
    length: PropTypes.string.isRequired,
    error: PropTypes.string,
    autoFocus: PropTypes.bool,
    onChange: PropTypes.func,
    cleanError: PropTypes.func
};
const PincodeStyled = styled(PinCode)(({theme}) => ({
    ' .input': {
        width: '40px',
        border: 'none',
        borderBottom: '1px solid #BDBDBD',
        paddingBottom: '10px',
        textAlign: 'center',
        borderRadius: '0',
        outline: 'none',
        margin: '0 10px',
        fontSize: '32px',
        fontWeight: '600',
        color: '#4B4D58',
        '   &:focus': {
            borderBottom: '1px solid #D12E28'
        }
    },
    '.active': {
        borderBottom: '1px solid #BDBDBD'
    },
    '.error': {borderBottom: '1px solid #D12E28'},
    '.textError': {position: 'absolute', left: '50%', transform: 'translate(-50%, 0)', color: '#D12E28'}
}));
export default PincodeStyled;
