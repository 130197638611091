import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Box, Container, IconButton, List, ListItem, ListItemText, SvgIcon} from '@mui/material';
import Logo from 'public/logo.svg';
import {routes as landingRoutes} from 'layouts/LandingLayout/routes';
import {routes as ppvRoutes} from 'layouts/PpvLayout/routes';
import Link from 'components/Link';
import MenuIcon from '@mui/icons-material/Menu';
import {Close} from '@mui/icons-material';
import {styled} from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import MuiDrawer from '@mui/material/Drawer';
import Authorization from 'components/Header/Authorization';
import {useRouter} from 'next/router';
import ROUTES from 'constants/routes';
import {useQuery} from '@apollo/client';
import {ME} from 'graphql/client/query/me';
import cookies from 'js-cookie';

const openedMixin = theme => ({
    width: '100%',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden',
    zIndex: 99999
});

const closedMixin = theme => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`
});

const Toolbar = styled(MuiToolbar)(({theme}) => ({
    height: 66,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: '0 !important',
    paddingRight: '0 !important'
}));

const AppBar = styled(MuiAppBar, {shouldForwardProp: prop => prop !== 'open'})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#FFF',
    color: 'white',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    boxShadow: '0px 3px 6px rgba(140, 152, 164, 0.25)'
}));

const Drawer = styled(MuiDrawer)(({theme, open}) => ({
    width: '100%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));

const Header = ({type}) => {
    const {pathname} = useRouter();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const {data, error, refetch} = useQuery(ME);
    const {push, query} = useRouter();
    const openAuth = () => {
        setOpenDrawer(false);
        cookies.get('accessToken') ? cookies.remove('accessToken', push('/')) : setOpenModal(true);
    };
    const handleDrawerToggle = () => {
        setOpenDrawer(!openDrawer);
    };
    useEffect(() => {
        aboutMe();
    }, [data]);
    const aboutMe = () => refetch();

    const routes =
        (data?.me?.type === 'PHYSICAL' && !error && landingRoutes) ||
        (data?.me?.type === 'LEGAL' && !error && ppvRoutes) ||
        landingRoutes;

    return (
        <AppBar position="static">
            <Container>
                <Toolbar>
                    <Box
                        sx={{
                            display: {xs: 'none', md: 'flex'},
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                        <Link href={ROUTES.ABOUT_US} sx={{display: 'flex'}}>
                            <SvgIcon inheritViewBox component={Logo} sx={{width: 100, height: 39}} />
                        </Link>

                        <Box sx={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                            {routes.map((item, index) => (
                                <Link
                                    sx={{
                                        display: 'block',
                                        textDecoration: 'none',
                                        fontWeight: 400,
                                        color:
                                            pathname === item.href
                                                ? theme => theme.palette.primary.main
                                                : theme => theme.palette.text,
                                        mx: 2,
                                        '&:last-of-type': {
                                            marginRight: 0
                                        }
                                    }}
                                    href={item.href}
                                    key={index}>
                                    {item.title}
                                </Link>
                            ))}
                            <Authorization info={data} err={error} {...{aboutMe, setOpenModal, openModal}} />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: {xs: 'flex', md: 'none'},
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                        {/* {type === 'ppv' && (
                            <Avatar sx={{bgcolor: theme => theme.palette.primary.main, color: '#FFF'}} />
                        )}
                        {type === 'landing' && ( */}
                        <Link href={ROUTES.ABOUT_US} sx={{display: 'flex'}}>
                            <SvgIcon inheritViewBox component={Logo} sx={{width: 100, height: 39}} />
                        </Link>
                        {/* )} */}
                        <IconButton
                            color="primary"
                            aria-label="open drawer"
                            onClick={handleDrawerToggle}
                            edge="start"
                            sx={{marginRight: -1}}>
                            <MenuIcon color={'primary'} />
                        </IconButton>
                        <Drawer variant="temporary" open={openDrawer} anchor={'right'}>
                            <Box sx={{px: 2.5}}>
                                <Toolbar sx={{display: 'flex', justifyContent: 'end'}} component={Container}>
                                    <IconButton onClick={handleDrawerToggle} color={'primary'}>
                                        <Close />
                                    </IconButton>
                                </Toolbar>
                                <List>
                                    {routes.map((item, index) => (
                                        <Link
                                            href={item.href}
                                            key={index}
                                            sx={{
                                                textDecoration: 'none',
                                                color:
                                                    pathname === item.href
                                                        ? theme => theme.palette.primary.main
                                                        : theme => theme.palette.text
                                            }}>
                                            <ListItem button>
                                                <ListItemText primary={item.title} />
                                            </ListItem>
                                        </Link>
                                    ))}
                                    <ListItem button onClick={openAuth} sx={{mt: 3}}>
                                        <ListItemText
                                            primary={cookies.get('accessToken') ? 'Выйти' : 'Войти'}
                                            sx={{color: 'primary.main'}}
                                        />
                                    </ListItem>
                                </List>
                            </Box>
                        </Drawer>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
